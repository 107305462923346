import { FaFacebookF, FaInstagram, FaYelp } from 'react-icons/fa';

// import resto1 from "../assets/images/resto/01.jpg";
// import resto2 from "../assets/images/resto/02.jpg";
// import resto3 from "../assets/images/resto/03.jpg";
// import resto4 from "../assets/images/resto/04.jpg";
// import resto5 from "../assets/images/resto/05.jpg";
// import resto6 from "../assets/images/resto/06.jpg";

// import services1 from "../assets/images/cutlery.png";
// import services2 from "../assets/images/resto/pizza.png";
// import services3 from "../assets/images/resto/scooter.png";
// import {FiSettings, FaRedo, FiShield,LuTag } from '../assets/icons/vander'

// data.js
export const about = [
  {
    id: '01',
    title: 'Epic Celebration Package',
    // desc: 'We make it a priority to offer flexible services to meet your lively Colors needs.',
    privateRoom: '2 hours of exclusive use (Extensions available with advance notice)',
    guests: 'Minimum of 12 kids',
    adults: '2 adults per child included (Additional adults: $7 each)',
    whatsIncluded: ['Pizza', 'Juice Boxes', 'Water', 'Napkins & Plates', 'Pretzels'],
  },
  {
    id: '02',
    title: 'Supreme Party Package',
    // desc: 'We make it a priority to offer flexible services to meet your deluxe celebration needs.',
    privateRoom: '3 hours of exclusive use (Extensions available with advance notice)',
    guests: 'Minimum of 20 kids',
    adults: '2 adults per child included (Additional adults: $5 each)',
    whatsIncluded: ['Pizza', 'Juice Boxes', 'Water', 'Napkins & Plates', 'Pretzels', 'Cupcakes'],
  },
  {
    id: '03',
    title: 'Grand Fiesta Package',
    // desc: 'We ensure the best support for your celebration with our dedicated team.',
    privateRoom: '1.5 hours of exclusive use (Extensions available with advance notice)',
    guests: 'Minimum of 10 kids',
    adults: '2 adults per child included (Additional adults: $6 each)',
    whatsIncluded: ['Pizza', 'Juice Boxes', 'Water', 'Napkins & Plates'],
  },
  {
    id: '04',
    title: 'Ultimate Festivity Bundle',
    // desc: 'Quality celebrations at prices that won’t break the bank.',
    privateRoom: '2.5 hours of exclusive use (Extensions available with advance notice)',
    guests: 'Minimum of 15 kids',
    adults: '2 adults per child included (Additional adults: $7 each)',
    whatsIncluded: ['Pizza', 'Juice Boxes', 'Water', 'Napkins & Plates', 'Pretzels', 'Ice Cream'],
  },
];

export const packagesData = [
  {
    id: 1,
    title: 'Party package',
    // desc: 'Exclusive access to our private room, perfect for intimate celebrations.',
    // name: 'Private Room Package',
    pricing: [
      {
        kids: 10,
        standardPrice: 425,
        weekendPrice: 425,
      },
      {
        kids: 15,
        standardPrice: 550,
        weekendPrice: 550,
      },
      {
        kids: 20,
        standardPrice: 670,
        weekendPrice: 670,
      },
    ],
    whatsIncluded: [
      '2,5 hours open space',
      'Plastic tablecloths',
      'Napkins',
      'Juice box or soda',
      'Pizza',
      'Gift for BIRTHDAY CHILD',
    ],
    additionalChildPrice: {
      standard: {
        10: 38,
        15: 34,
        20: 29,
      },
      weekend: {
        10: 38,
        15: 34,
        20: 29,
      },
    },
  },
];

// export const packagesData = [
//   // 1) OPEN SPACE PARTY
//   {
//     id: 1,
//     title: 'Open Space Party',
//     desc: 'Enjoy our main playground during normal operating hours, with a reserved table for your group. Ideal for those who prefer a fun, lively atmosphere without full exclusivity.',
//     pricing: [
//       {
//         kids: 10,
//         standardPrice: 425,
//         weekendPrice: 425,
//       },
//       {
//         kids: 15,
//         standardPrice: 550,
//         weekendPrice: 550,
//       },
//       {
//         kids: 20,
//         standardPrice: 670,
//         weekendPrice: 670,
//       },
//     ],
//     whatsIncluded: [
//       '2.5 hours in the main playground (shared with the public)',
//       'Reserved party tables with plastic tablecloths & napkins',
//       'Pizza (1-2 slices per child)',
//       'Choice of juice box or soda per child',
//       'A small gift for the birthday child',
//       'Setup & cleanup assistance by our staff',
//     ],
//     additionalChildPrice: {
//       standard: {
//         10: 38,
//         15: 34,
//         20: 29,
//       },
//       weekend: {
//         10: 38,
//         15: 34,
//         20: 29,
//       },
//     },
//     availability: 'Available Monday through Saturday morning only.',
//   },

//   // 2) PARTY ROOM + SHARED PLAYGROUND
//   {
//     id: 2,
//     title: 'Party Room + Shared Playground',
//     desc: 'Get your own private party room for food, gifts, and downtime, while still enjoying access to our main playground with other guests.',
//     pricing: [
//       {
//         kids: 10,
//         standardPrice: 525,
//         weekendPrice: 525,
//       },
//       {
//         kids: 15,
//         standardPrice: 650,
//         weekendPrice: 650,
//       },
//       {
//         kids: 20,
//         standardPrice: 775,
//         weekendPrice: 775,
//       },
//     ],
//     whatsIncluded: [
//       '2.5 hours total party time',
//       'Dedicated private room for eating and celebrating',
//       'Shared access to the playground (open to other guests)',
//       'Plastic tablecloths & napkins',
//       'Pizza (1-2 slices per child)',
//       'Choice of juice box or soda per child',
//       'A small gift for the birthday child',
//       'Setup & cleanup assistance by our staff',
//     ],
//     additionalChildPrice: {
//       standard: {
//         10: 40,
//         15: 36,
//         20: 32,
//       },
//       weekend: {
//         10: 40,
//         15: 36,
//         20: 32,
//       },
//     },
//     availability:
//       'Available Monday through Sunday. Room occupancy limits apply; please inquire if you have special needs.',
//   },

//   // 3) PRIVATE PLAYGROUND PARTY
//   {
//     id: 3,
//     title: 'Private Playground Party',
//     desc: 'Exclusive use of our entire playground—no other guests or parties will be allowed during your reserved time. Perfect for large groups or those seeking a VIP experience.',
//     pricing: [
//       {
//         kids: 10,
//         standardPrice: 850,
//         weekendPrice: 950,
//       },
//       {
//         kids: 15,
//         standardPrice: 975,
//         weekendPrice: 1075,
//       },
//       {
//         kids: 20,
//         standardPrice: 1100,
//         weekendPrice: 1200,
//       },
//     ],
//     whatsIncluded: [
//       '2.5 hours of full private access to the playground',
//       'Set of plastic tablecloths & napkins',
//       'Pizza (1-2 slices per child)',
//       'Choice of juice box or soda per child',
//       'A special gift for the birthday child',
//       'Dedicated staff for setup & cleanup',
//     ],
//     additionalChildPrice: {
//       standard: {
//         10: 45,
//         15: 40,
//         20: 35,
//       },
//       weekend: {
//         10: 50,
//         15: 45,
//         20: 40,
//       },
//     },
//     availability: 'Available exclusively on Saturdays & Sundays.',
//   },
// ];

export const menu = [
  // Hot Food
  {
    category: 'hotfood',
    name: 'Cheese Pizza',
    desc: 'Delicious cheese-topped pizza with a crispy crust.',
  },
  {
    category: 'hotfood',
    name: 'Pepperoni Pizza',
    desc: 'Classic pepperoni pizza with savory slices.',
  },
  {
    category: 'hotfood',
    name: 'French Fries',
    desc: 'Golden french fries.',
  },
  {
    category: 'hotfood',
    name: 'Chicken Nuggets',
    desc: 'Crispy and tender chicken nuggets.',
  },
  {
    category: 'hotfood',
    name: 'Fish Sticks',
    desc: 'Breaded fish sticks.',
  },
  {
    category: 'hotfood',
    name: 'Hot Dog',
    desc: 'Served in a bun.',
  },

  // Snacks
  {
    category: 'snacks',
    name: 'Pretzels',
    desc: 'Soft and salty pretzels.',
  },
  {
    category: 'snacks',
    name: 'Granola Bars',
    desc: 'Crunchy and nutritious granola bars.',
  },
  {
    category: 'snacks',
    name: 'Cookies',
    desc: 'Assorted crunchy cookies.',
  },
  {
    category: 'snacks',
    name: 'fruit Snacks',
    desc: 'Sweet and chewy fruit-flavored snacks.',
  },
  {
    category: 'snacks',
    name: 'Gold Fishes',
    desc: 'Crunchy and flavorful cheese-flavored Goldfish crackers.',
  },
  {
    category: 'snacks',
    name: 'Chips',
    desc: 'Crispy and savory potato chips.',
  },
  {
    category: 'snacks',
    name: 'Fruits',
    desc: 'Fresh and assorted seasonal fruits.',
  },
  {
    category: 'snacks',
    name: 'Yogurts',
    desc: 'Multiple flavors.',
  },

  // Sweets
  {
    category: 'sweets',
    name: 'Donuts',
    desc: 'Sweet and soft glazed donuts.',
  },
  {
    category: 'sweets',
    name: 'Muffins',
    desc: 'Moist and flavorful muffins available in various flavors.',
  },
  {
    category: 'sweets',
    name: 'Cookies',
    desc: 'Assorted sweet cookies with different flavors.',
  },
  {
    category: 'sweets',
    name: 'Ice Cream',
    desc: 'Creamy and delicious ice cream in multiple flavors.',
  },
  {
    category: 'sweets',
    name: 'Candy',
    desc: 'A variety of colorful and tasty candies.',
  },
  {
    category: 'sweets',
    name: 'Lollipops',
    desc: 'Assorted flavors of lollipops.',
  },
  {
    category: 'sweets',
    name: 'Cotton Candy',
    desc: 'Fluffy and sweet cotton candy.',
  },

  // Hot Drinks
  {
    category: 'hotdrinks',
    name: 'Hot Coco',
    desc: 'Rich and creamy hot cocoa topped with marshmallows.',
  },
  {
    category: 'hotdrinks',
    name: 'Espresso',
    desc: 'Strong and bold espresso shot.',
  },
  {
    category: 'hotdrinks',
    name: 'Cappuccino',
    desc: 'Espresso with steamed milk foam.',
  },
  {
    category: 'hotdrinks',
    name: 'Latte',
    desc: 'Rich and velvety espresso combined with steamed milk for a smooth finish.',
  },
  {
    category: 'hotdrinks',
    name: 'Macchiato',
    desc: 'Espresso with steamed milk.',
  },
  {
    category: 'hotdrinks',
    name: 'Mochaccino',
    desc: 'A blend of espresso, steamed milk, and chocolate.',
  },
  {
    category: 'hotdrinks',
    name: 'Tea',
    desc: 'Freshly brewed tea available in various flavors.',
  },
  {
    category: 'hotdrinks',
    name: 'Mocha',
    desc: 'Espresso mixed with chocolate and steamed milk.',
  },

  // Cold Beverages
  {
    category: 'coldbeverages',
    name: 'Water',
    desc: 'Refreshing bottled water.',
  },
  {
    category: 'coldbeverages',
    name: 'Juices',
    desc: 'Freshly packed fruit juices.',
  },
  {
    category: 'coldbeverages',
    name: 'Milk',
    desc: 'Fresh and nutritious milk.',
  },
  {
    category: 'coldbeverages',
    name: 'Chocolate Milk',
    desc: 'Creamy and sweet chocolate milk.',
  },
  {
    category: 'coldbeverages',
    name: 'Sodas',
    desc: 'A variety of carbonated soft drinks.',
  },
];

export const services = [
  {
    // image: services1,
    title: 'Waiver Agreement Needed',
    desc: 'Before entering the playground, a signed waiver is required for each participant.',
  },
  {
    // image: services2,
    title: 'Feet-Friendly Gear',
    desc: 'Children and adults must wear socks at all times in the play area, with no exceptions. \n*(Socks and shoe covers are available for purchase if needed.)',
  },
  {
    // image: services3,
    title: 'The food is great, however...',
    desc: 'Unfortunately, no outside food or drinks are allowed in the play area.',
  },
];

export const rules = [
  {
    id: 1,
    title: 'Play Safe!',
    desc: "Always play gently with others and be mindful of your surroundings. No pushing, shoving, or roughhousing. Let's make sure everyone has fun while staying safe!",
  },
  {
    id: 2,
    title: 'Socks Only!',
    desc: "Shoes and trainers off—socks on! Bare feet are a no-go. We've got extra socks if you need them.",
  },
  {
    id: 3,
    title: 'Parents Watch!',
    desc: 'Great playtime starts with great supervision! Parents, please keep an eye on your little adventurers at all times.',
  },
  {
    id: 4,
    title: 'No Sharp Stuff!',
    desc: 'Sharp objects like pens, pencils, and jewelry need to stay out of the play areas. Let’s keep the focus on fun!',
  },
  {
    id: 5,
    title: 'Snack Zone Only!',
    desc: 'Enjoy snacks and drinks in the designated eating areas only. No food or drinks on the play equipment, please!',
  },
  {
    id: 6,
    title: 'Mind Your Stuff!',
    desc: 'Personal items should stay with parents. We can’t watch your stuff, so please keep your valuables safe.',
  },
  {
    id: 7,
    title: 'Feeling Icky? Rest Up!',
    desc: "If your little one isn’t feeling well, it's best to stay home and rest. Healthy kids make for the best playdates!",
  },
  // {
  //   id: 8,
  //   title: "We’re First Aid Ready!",
  //   desc: "Our team is equipped with first aid supplies and ready to assist with any little bumps or bruises.",
  // },
  // {
  //   id: 9,
  //   title: "Walk, Don’t Run!",
  //   desc: "Running is for outside—inside, let’s walk to avoid any accidents. Slow and steady keeps the play safe!",
  // },
  {
    id: 8,
    title: 'Follow the Limits!',
    desc: 'Our equipment is designed for specific age and height groups. Please follow the guidelines to keep everyone safe!',
  },
  {
    id: 9,
    title: 'No Smoking!',
    desc: 'Smoking isn’t allowed anywhere on the premises. Let’s keep the air fresh and the smiles big!',
  },
  {
    id: 10,
    title: 'Be Kind!',
    desc: "Be kind and considerate to everyone around you. Share the fun and respect others' space and belongings.",
  },
  // {
  //   id: 13,
  //   title: "Listen Up!",
  //   desc: "Our staff is here to help and keep everyone safe. Please follow their instructions and ask if you need anything.",
  // },
  {
    id: 11,
    title: 'Tidy Up!',
    desc: 'When you’re done with toys or snacks, please help by putting them back where they belong or throwing away any trash.',
  },
  {
    id: 12,
    title: 'Have Fun!',
    desc: 'The most important rule of all—enjoy your time here and make lots of happy memories!',
  },
];

export const client = [
  {
    desc: 'great review 222',
    rate: [
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
    ],
    name: 'Aaa bbb',
  },
  {
    desc: 'good place',
    rate: [
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
    ],
    name: 'ddd bbb',
  },
  {
    desc: '1 review',
    rate: [
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
      'mdi mdi-star text-warning',
    ],
    name: 'fff fggg',
  },
];

export const social = [
  {
    name: 'Facebook',
    icon: <FaFacebookF />, // Facebook icon
    url: 'https://www.facebook.com/people/Funlandia-Kid-Cafe/61566641342106/?_rdr', // Replace with your Facebook URL
  },
  {
    name: 'Instagram',
    icon: <FaInstagram />, // Instagram icon
    url: 'https://www.instagram.com/funladiakidcafe/', // Replace with your Instagram URL
  },
  {
    name: 'Yelp',
    icon: <FaYelp />, // Yelp icon
    url: 'https://www.yelp.com/biz/funlandia-kid-cafe-palatine', // Replace with your Yelp URL
  },
  // 'mdi mdi-facebook',
  // // "mdi mdi-twitter",
  // // "mdi mdi-linkedin",
  // 'mdi mdi-instagram',
  // // "mdi mdi-google-plus",
  // // "mdi mdi-vimeo",
  // // "mdi mdi-youtube",
];

export const pricing = [
  {
    title: 'Free',
    titleBg: false,
    tag: false,
    feature: ['0-8 months free'],
    amount: '0',
  },
  {
    title: 'Daily (Weekday)',
    titleBg: true,
    tag: false,
    feature: [],
    amount: '16',
  },
  {
    title: 'Daily (Weekends)',
    titleBg: false,
    tag: false,
    feature: [' '],
    amount: '19',
  },
  {
    title: 'Ultimate',
    titleBg: false,
    tag: false,
    feature: [' '],
    amount: '45.95',
  },
];

// src/data/data.js

// src/data/data.js

export const portfolioData = [
  {
    id: 1,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/lssejvht8rrvxk5x1nhp',
    title: 'Funlandia Image 1',
    description: 'Description for Funlandia Image 1',
  },
  {
    id: 2,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/cusk11wpfgtxz3582btn',
    title: 'Funlandia Image 2',
    description: 'Description for Funlandia Image 2',
  },
  {
    id: 3,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/i5tvvuz1pll4l0einbig',
    title: 'Funlandia Image 3',
    description: 'Description for Funlandia Image 3',
  },
  {
    id: 4,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/kthptnny95q9xg0m9ebq',
    title: 'Funlandia Image 4',
    description: 'Description for Funlandia Image 4',
  },
  {
    id: 5,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/da1mkobi57eomef6fcby',
    title: 'Funlandia Image 5',
    description: 'Description for Funlandia Image 5',
  },
  {
    id: 6,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/gwkqb1bvleuhq3jrcuir',
    title: 'Funlandia Image 6',
    description: 'Description for Funlandia Image 6',
  },
  {
    id: 7,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/twznfhp2zxyvfkrrsuk9',
    title: 'Funlandia Image 7',
    description: 'Description for Funlandia Image 7',
  },
  {
    id: 8,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/dgb3xanhc9ufobouaygw',
    title: 'Funlandia Image 8',
    description: 'Description for Funlandia Image 8',
  },
  {
    id: 9,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/kbsizctxzkaccoor5lft',
    title: 'Funlandia Image 9',
    description: 'Description for Funlandia Image 9',
  },
  {
    id: 10,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/j9lt7xgtmr59kf0zzjiu',
    title: 'Funlandia Image 10',
    description: 'Description for Funlandia Image 10',
  },
  {
    id: 11,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/xyknrdqmk9ujvlr8pggi',
    title: 'Funlandia Image 11',
    description: 'Description for Funlandia Image 11',
  },
  {
    id: 12,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/wspodv4nmbhvjxnl8ek5',
    title: 'Funlandia Image 12',
    description: 'Description for Funlandia Image 12',
  },
  {
    id: 13,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/jvj8ecvgwg0oy9pugp3v',
    title: 'Funlandia Image 13',
    description: 'Description for Funlandia Image 13',
  },
  {
    id: 14,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/fag027fn3pbrc6ryexn9',
    title: 'Funlandia Image 14',
    description: 'Description for Funlandia Image 14',
  },
  {
    id: 15,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/y1dak9ep2cojrytnv88o',
    title: 'Funlandia Image 15',
    description: 'Description for Funlandia Image 15',
  },
  {
    id: 16,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/vnrq0rd4kqtsrma9sxkt',
    title: 'Funlandia Image 16',
    description: 'Description for Funlandia Image 16',
  },
  {
    id: 17,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/ytkucyd4vin4lgdm9h1f',
    title: 'Funlandia Image 17',
    description: 'Description for Funlandia Image 17',
  },
  {
    id: 18,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/h3flmv80kdvyscrcecgc',
    title: 'Funlandia Image 18',
    description: 'Description for Funlandia Image 18',
  },
  {
    id: 19,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/wxazsftxkjtvg6smehqe',
    title: 'Funlandia Image 19',
    description: 'Description for Funlandia Image 19',
  },
  {
    id: 20,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/nxsg0dh63cnwt8jzigez',
    title: 'Funlandia Image 20',
    description: 'Description for Funlandia Image 20',
  },
  {
    id: 21,
    category: 'kids',
    publicId: 'funlandia-kid-cafe-web-app/dp88jeiybxfettdnmogr',
    title: 'Funlandia Image 21',
    description: 'Description for Funlandia Image 21',
  },
  // Add more images as needed
];

export const portfolioImg = [
  // portfolio1,
  // portfolio2,
  // portfolio3,
  // portfolio4,
  // portfolio5,
  // portfolio6,
  // portfolio7,
  // portfolio8,
  // portfolio9,
];
